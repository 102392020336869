<template>
  <div>
    <el-col>
      <template
        >You can apply Filters on entity data by selecting entity
        field.</template
      >
      <el-button @click="addFilter" type="text">Add Filter+</el-button>
      <br />
      <div
        style="display: flex !important"
        v-if="entityFiltersData && entityFiltersData._id"
      >
        <p v-if="entityFiltersData.filter_name">
          Selected Filter:
          <span style="font-weight: 600">
            {{ entityFiltersData.filter_name }}
          </span>
        </p>
        <el-tooltip
          class="item"
          effect="dark"
          content="Delete filter"
          placement="top-start"
        >
          <el-link
            class="mb-1"
            type="danger"
            :underline="false"
            @click="deleteFilter"
          >
            <i class="el-icon-delete"></i>
          </el-link>
        </el-tooltip>
      </div>
      <el-divider></el-divider>

      <div
        class="mt-2"
        v-if="
          entityFiltersData &&
          entityFiltersData.filters &&
          entityFiltersData.filters.length
        "
      >
        Query type
        <el-radio-group v-model="entityFiltersData.query_type">
          <el-radio label="AND"></el-radio>
          <el-radio label="OR"></el-radio>
        </el-radio-group>
      </div>
      <div v-else>There is no filter selected. Please click on Add Filter.</div>
      <br />
      <el-scrollbar wrap-style="max-height: 250px;" ref="filterScroll">
        <table v-if="entityFiltersData.filters">
          <tr
            v-for="(filter, index) in entityFiltersData.filters"
            :key="index + '-' + filter.field"
          >
            <td>
              <tr>
                <td>
                  <el-tooltip
                    effect="dark"
                    content="Select entity field"
                    placement="top-start"
                  >
                    <el-select
                      v-model="filter.field"
                      placeholder="Entity Field"
                      filterable
                      size="mini"
                      @change="
                        (changedValue) =>
                          checkCurrentFieldData(changedValue, filter)
                      "
                    >
                      <el-option
                        v-for="(field, index) of selectedEntityFields.filter(
                          (e) => contentFields.indexOf(e.inputType) == -1
                        )"
                        :key="index"
                        :value="field.key"
                        :label="`${field.template_name} - ${field.label}`"
                        :disabled="checkFilterExistedOrNot(field)"
                      >
                        {{ field.template_name }} -
                        {{ field.label }}
                      </el-option>
                      <el-option value="created_at" 
                        >{{ getButtonLabel('Created at', 'Created Date') }}</el-option
                      >
                      <el-option value="updated_at"
                        >{{ getButtonLabel('Updated at', 'Updated Date') }}</el-option
                      >
                      <el-option value="created_by"
                        >{{ getButtonLabel('Created by', 'Created By') }}</el-option
                      >
                      <el-option value="updated_by"
                        >{{ getButtonLabel('Updated by', 'Updated By') }}</el-option
                      >
                      <el-option
                        value="status"
                        label="Status"
                        v-if="currentEntity.entity_type !== 'INDIVIDUAL'"
                      ></el-option>
                      <el-option
                        value="isCreatedFromFormbuilder"
                        label="Is created from form builder?"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td v-if="filter.field !== 'isCreatedFromFormbuilder'">
                  <el-tooltip
                    effect="dark"
                    content="Select operator"
                    placement="top-start"
                  >
                    <el-select
                      v-model="filter.operator"
                      size="mini"
                      :disabled="!filter.field"
                      placeholder="Operator"
                    >
                      <el-option
                        v-for="(operator, index) of getCurrentFieldOperators(
                          filter
                        )"
                        :key="index"
                        :label="operator.title"
                        :value="operator.value"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY'
                        "
                        value="=text-includes"
                        label="Text Includes"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td
                  v-if="
                    filter.field == 'isCreatedFromFormbuilder' &&
                    filter.data_type == 'isCreatedFromFormbuilder'
                  "
                >
                  <el-select v-model="filter.value" size="mini">
                    <el-option
                      :value="true"
                      label="Created from form builder"
                    ></el-option>
                    <el-option
                      :value="false"
                      label="Not created from form builder"
                    ></el-option>
                  </el-select>
                </td>
                <td
                  v-if="
                    filter.field == 'status' && filter.data_type == 'STATUS'
                  "
                >
                  <el-select v-model="filter.value" size="mini">
                    <el-option value="ACTIVE" label="Active"></el-option>
                    <el-option value="ARCHIVED" label="Deleted"></el-option>
                  </el-select>
                </td>
                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists' &&
                    filter.operator != 'between' &&
                    filter.operator != 'notbetween' &&
                    filter.operator != 'dateinclude' &&
                    filter.operator != 'notdateinclude' &&
                    filter.operator != 'real_time' &&
                    filter.operator != 'weekdays' &&
                    filter.operator != 'filter' &&
                    filter.data_type !== 'STATUS' &&
                    filter.data_type !== 'isCreatedFromFormbuilder'
                  "
                >
                  <el-tooltip
                    effect="dark"
                    content="Select value type"
                    placement="top-start"
                  >
                    <el-select
                      v-if="
                        filter.operator != 'lastmonth' &&
                        filter.operator != 'thismonth'
                      "
                      v-model="filter.data_source"
                      @change="(value) => checkSourceDataChange(value, filter)"
                      size="mini"
                      placeholder="Value type"
                      :disabled="!filter.operator"
                    >
                      <el-option
                        v-for="(source, index) of allowedDataSources(filter)"
                        :key="index"
                        :label="source"
                        :value="source"
                      ></el-option>
                      <el-option
                        value="TEMPLATE"
                        label="Template"
                        v-if="
                          checkTemplateCondition(filter) &&
                          filter.data_type != 'created_by' &&
                          filter.data_type != 'updated_by' &&
                          filter.data_type != 'created_at' &&
                          filter.data_type != 'updated_at' &&
                          filter.input_type != 'SELECT'
                        "
                      ></el-option>
                      <el-option
                        value="LOGIN_USER"
                        label="Login User"
                        v-if="
                          filter.data_type == 'created_by' ||
                          filter.data_type == 'updated_by'
                        "
                      ></el-option>
                      <el-option
                        v-if="filter.data_type == 'WEEKDAYS'"
                        value="weekdays"
                        label="Weekdays"
                      ></el-option>
                      <el-option
                        v-if="checkIsSelect(filter)"
                        value="field_option"
                        label="From field"
                      ></el-option>
                      <el-option
                        v-if="checkSelfTemplateFields(filter)"
                        value="self_field"
                        label="Template"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.data_type == 'STRING' &&
                          allowedLoginFilterOp.indexOf(filter.operator) != -1
                        "
                        value="login_user"
                        label="FROM LOGIN USER"
                      ></el-option>
                      <el-option
                        v-if="
                          filter.input_type == 'SELECT' &&
                          filter.data_type == 'ARRAY' &&
                          filter.operator == '=text-includes'
                        "
                        value="VALUE"
                        label="Value"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </td>
                <td
                  v-if="
                    filter.operator != 'exists' &&
                    filter.operator != 'notexists'
                  "
                >
                    <div>
                      <el-select
                        v-if="filter.data_source == 'TEMPLATE'"
                        v-model="filter.value_field"
                        size="mini"
                        placeholder="Template fields"
                      >
                        <el-option
                          v-for="(
                            enityData, index
                          ) of getSelfTemplateFieldsByType(filter)"
                          :key="index"
                          :label="enityData.label"
                          :value="enityData.key"
                        ></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'BOOLEAN' &&
                          filter.data_source != 'TEMPLATE'
                        "
                        size="mini"
                      >
                        <el-option :value="true" label="Checked"></el-option>
                        <el-option :value="false" label="Unchecked"></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'DATE' &&
                          filter.data_source == 'CURRENT_DATE'
                        "
                        size="mini"
                      >
                        <el-option value="today" label="Today"></el-option>
                      </el-select>

                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'YES_OR_NO' &&
                          filter.data_source != 'TEMPLATE'
                        "
                        size="mini"
                      >
                        <el-option value="YES" label="Yes"></el-option>
                        <el-option value="NO" label="No"></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'DATE' &&
                          filter.operator == 'weekdays'
                        "
                        size="mini"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(option, index) of weekdays_options"
                          :value="option.value"
                          :label="option.name"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_source == 'weekdays' &&
                          filter.data_type == 'WEEKDAYS'
                        "
                        size="mini"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(option, index) of weekdays_options"
                          :value="option.key"
                          :label="option.name"
                          :key="index"
                        ></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.relational_filter_query"
                        v-if="
                          filter.data_type == 'RELATION' &&
                          filter.operator == 'filter'
                        "
                        :disabled="!checkIsFirstRelationalField(index)"
                        @change="setRelationalFilterQuery"
                        size="mini"
                      >
                        <el-option
                          v-for="(item,index) in [{label :'Includes',value : 'includes'},{label :'Not includes',value : 'not_includes'}]"
                          :key="index"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'RELATION' &&
                          filter.operator == 'filter'
                        "
                        size="mini"
                      >
                        <el-option
                          v-for="(
                            source, index
                          ) of getSelectedRelationalEntityFilters(filter)"
                          :key="index"
                          :label="source.filter_name"
                          :value="source._id"
                        >
                        </el-option>
                      </el-select>

                      <el-select
                        v-model="filter.value_field"
                        size="mini"
                        v-if="filter.data_source == 'login_user'"
                        @change="
                          (changedValue) =>
                            checkCurrentFieldValue(changedValue, filter)
                        "
                      >
                        <el-option
                          v-for="(op, index) in loginUserFields"
                          :key="index + op.value"
                          :label="op.label"
                          :value="op.value"
                          >{{ op.label }}</el-option
                        >
                      </el-select>
                      <el-input
                        v-model="filter.value"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          (filter.data_type == 'STRING' ||
                            (filter.input_type == 'SELECT' &&
                              filter.data_type == 'ARRAY' &&
                              filter.operator == '=text-includes'))
                        "
                        size="mini"
                      ></el-input>

                      <div
                        v-if="
                          (filter.data_type == 'NUMBER' ||
                            filter.data_type == 'CURRENCY') &&
                          filter.operator == 'between'
                        "
                      >
                        <el-input-number
                          v-model="filter.range1"
                          :controls="false"
                          size="mini"
                          placeholder="starting value"
                          :min="0"
                          style="margin-right: 15px"
                        ></el-input-number>
                        <span>To</span>
                        <el-input-number
                          v-model="filter.range2"
                          :controls="false"
                          size="mini"
                          :min="0"
                          placeholder="ending value"
                          style="margin-left: 15px"
                        ></el-input-number>
                      </div>
                      <!-- <el-input-number
                        v-model="filter.value"
                        v-if="
                          filter.data_type == 'DATE' &&
                          (filter.operator == 'after' ||
                            filter.operator == 'before')
                        "
                        :controls="false"
                        size="mini"
                      ></el-input-number> -->
                    <div
                    v-if="
                      filter.data_type == 'DATE' &&
                      (filter.operator == 'after' || filter.operator == 'before') && 
                      filter.data_source=='VALUE'
                    "
                    class="counter-box"
                  >
                    <el-input-number
                      v-model="filter.value"
                      size="mini"
                      :min="1"
                      style="width: 100px"
                    ></el-input-number>
                    <el-select
                      v-model="filter.units"
                      size="mini"
                      style="width: 100px; margin-left: 4px"
                    >
                      <el-option value="days" label="Day(s)"></el-option>
                      <el-option value="weeks" label="Week(s)"></el-option>
                      <el-option value="months" label="Month(s)"></el-option>
                      <el-option value="years" label="Year(s)"></el-option>
                    </el-select>
                  </div>
                      <el-input-number
                        v-model="filter.value"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'NUMBER' &&
                          filter.operator != 'between'
                        "
                        :controls="false"
                        size="mini"
                      ></el-input-number>
                      <el-input-number
                        v-model="filter.value"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'CURRENCY'
                        "
                        :controls="false"
                        size="mini"
                      ></el-input-number>
                      <el-date-picker
                        v-model="filter.value"
                        size="mini"
                        placeholder="Select Date"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'DATE' &&
                          filter.operator != 'real_time' &&
                          filter.operator != 'between' &&
                          filter.operator != 'weekdays' &&
                          filter.operator != 'after' &&
                          filter.operator != 'before'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-time-picker
                        v-model="filter.value"
                        size="mini"
                        placeholder="select time"
                        v-if="
                          filter.data_type == 'TIME_RANGE' &&
                          filter.operator == 'timeinclude'
                        "
                      >
                      </el-time-picker>
                      <el-date-picker
                        v-model="filter.value"
                        placeholder="Select Date"
                        size="mini"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'DATE_TIME' &&
                          filter.operator != 'real_time' &&
                          filter.operator != 'between' &&
                          filter.operator != 'dateinclude' &&
                          filter.operator != 'notdateinclude' &&
                          filter.operator != 'weekdays'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-date-picker
                        v-model="filter.value"
                        size="mini"
                        v-else-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'created_at'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-select
                        v-if="
                          filter.input_type === 'created_by' &&
                          filter.data_source === 'VALUE'
                        "
                        v-model="filter.value"
                        size="mini"
                        multiple
                        collapse-tags
                        style="margin-left: 10px"
                      >
                        <el-option
                          v-for="(user, index) in users.data"
                          :key="index"
                          :label="user.email"
                          :value="user._id"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-if="
                          filter.input_type === 'updated_by' &&
                          filter.data_source === 'VALUE'
                        "
                        v-model="filter.value"
                        size="mini"
                        multiple
                        collapse-tags
                        style="margin-left: 10px"
                      >
                        <el-option
                          v-for="(user, index) in users.data"
                          :key="index"
                          :label="user.email"
                          :value="user._id"
                        >
                        </el-option>
                      </el-select>
                      <el-date-picker
                        v-model="filter.value"
                        size="mini"
                        v-else-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'updated_at'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-date-picker
                        v-model="filter.value"
                        size="mini"
                        v-if="
                          (filter.data_type == 'DATE_TIME' ||
                            filter.data_type == 'DATE_RANGE') &&
                          filter.operator == 'dateinclude'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-date-picker
                        v-model="filter.value"
                        size="mini"
                        v-if="
                          (filter.data_type == 'DATE_TIME' ||
                            filter.data_type == 'DATE') &&
                          filter.operator == 'notdateinclude'
                        "
                        :format="
                          getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                            ? getDefaultDateFormat
                                .replace('YYYY', 'yyyy')
                                .replace('DD', 'dd')
                            : 'MM-dd-yyyy'
                        "
                      ></el-date-picker>
                      <el-select
                        v-if="
                          filter.data_source == 'REAL_TIME_PICKER' &&
                          filter.data_type == 'DATE_TIME' &&
                          filter.operator != 'real_time' &&
                          filter.operator != 'between' &&
                          filter.operator != 'weekdays'
                        "
                        placeholder="Pick date range"
                        v-model="filter.selected_date_picker"
                        size="mini"
                        @change="
                          (changedValue) =>
                            setDatePickerDate(changedValue, filter)
                        "
                      >
                        <el-option label="Today" value="today">Today</el-option>
                        <el-option label="Yesterday" value="yesterday"
                          >Yesterday</el-option
                        >
                      </el-select>
                      <CustomDatePicker
                        v-if="
                          filter.data_type == 'DATE' &&
                          filter.operator == 'real_time'
                        "
                        :field="filter"
                      ></CustomDatePicker>
                      <el-date-picker
                        v-if="
                          filter.data_type == 'DATE' &&
                          filter.operator == 'between'
                        "
                        v-model="filter.value"
                        size="mini"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                      ></el-date-picker>
                      <el-date-picker
                        v-if="
                          filter.data_type == 'DATE' &&
                          filter.operator == 'notbetween'
                        "
                        v-model="filter.value"
                        size="mini"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                      ></el-date-picker>

                      <el-time-picker
                        is-range
                        v-if="
                          filter.data_type == 'TIME' &&
                          filter.operator == 'between' &&
                          isFixed(filter) === false
                        "
                        v-model="filter.value"
                        size="mini"
                        range-separator="To"
                        start-placeholder="Start time"
                        end-placeholder="End time"
                        :picker-options="{
                          format: 'HH:mm:ss',
                        }"
                        :value-format="'HH:mm:ss'"
                      ></el-time-picker>
                      <el-time-picker
                        is-range
                        v-if="
                          filter.data_type == 'TIME' &&
                          filter.operator == 'notbetween' &&
                          isFixed(filter) === false
                        "
                        v-model="filter.value"
                        size="mini"
                        range-separator="To"
                        start-placeholder="Start Time"
                        end-placeholder="End Time"
                        :picker-options="{
                          format: 'HH:mm:ss',
                        }"
                        :value-format="'HH:mm:ss'"
                      ></el-time-picker>
                      <el-time-picker
                        is-range
                        v-if="
                          filter.data_type == 'TIME' &&
                          filter.operator == 'between' &&
                          isFixed(filter) === true
                        "
                        v-model="filter.value"
                        size="mini"
                        range-separator="To"
                        start-placeholder="Start Time"
                        end-placeholder="End Time"
                        :picker-options="{
                          format: 'HH:mm:ss',
                        }"
                        :value-format="'HH:mm'"
                      ></el-time-picker>
                      <el-time-picker
                        is-range
                        v-if="
                          filter.data_type == 'TIME' &&
                          filter.operator == 'notbetween' &&
                          isFixed(filter) === true
                        "
                        v-model="filter.value"
                        size="mini"
                        range-separator="To"
                        start-placeholder="Start time"
                        end-placeholder="End time"
                        :picker-options="{
                          format: 'HH:mm:ss',
                        }"
                        :value-format="'HH:mm'"
                      ></el-time-picker>

                      <el-time-picker
                        v-model="filter.value"
                        size="mini"
                        placeholder="Select Time"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'TIME' &&
                          isFixed(filter) === false &&
                          filter.operator != 'between'
                        "
                        :picker-options="{
                          format: 'HH:mm:ss',
                        }"
                        :value-format="'HH:mm:ss'"
                      ></el-time-picker>

                      <el-time-select
                        v-model="filter.value"
                        v-if="
                          filter.data_source == 'VALUE' &&
                          filter.data_type == 'TIME' &&
                          isFixed(filter) === true &&
                          filter.operator != 'between'
                        "
                        placeholder="Select time"
                        size="mini"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '23:59',
                        }"
                      ></el-time-select>

                      <el-select
                        v-model="filter.global_variable_id"
                        v-if="filter.data_source == 'GLOBAL_VARIABLE'"
                        no-data-text="No Global Varibles with Field"
                        size="mini"
                        @change="
                          (value) => addGlobalVaraibleData(value, filter)
                        "
                      >
                        <el-option
                          v-for="(gbVab, index) of selectedGlobalVariablesList(
                            filter
                          )"
                          :key="index"
                          :label="`${gbVab.label} -> ${
                            gbVab.value ? gbVab.value : ''
                          }`"
                          :value="gbVab._id"
                        ></el-option>
                      </el-select>

                      <el-select
                        v-if="
                          filter.data_source == 'NEW_LIST' &&
                          filter.operator != 'real_time' &&
                          filter.operator != 'weekdays'
                        "
                        placeholder="Enter values"
                        v-model="filter.value"
                        :clearable="true"
                        :allow-create="true"
                        :default-first-option="true"
                        :filterable="true"
                        :multiple="true"
                        :no-data-text="''"
                        @change="(data) => parseOptionsAsNumbers(data, filter)"
                        size="mini"
                      />

                      <el-select
                        v-model="filter.value"
                        v-if="filter.data_source == 'ENTITY_DATA_LIST'"
                        multiple
                        collapse-tags
                        size="mini"
                        filterable
                      >
                        <el-option
                          v-for="(temp, i) in entilistAsOptions(filter)"
                          :key="temp._id + '_' + index + '_' + i"
                          :value="temp._id"
                          :label="getLabel(temp, i)"
                          >{{ getLabel(temp, i) }}</el-option
                        >
                      </el-select>

                      <el-input
                        v-if="
                          (filter.data_source == '' ||
                            filter.data_source == undefined) &&
                          filter.operator != 'between' &&
                          filter.operator != 'notbetween' &&
                          filter.operator != 'dateinclude' &&
                          filter.operator != 'notdateinclude' &&
                          filter.operator != 'real_time' &&
                          filter.operator != 'weekdays' &&
                          filter.operator == 'Equals'
                        "
                        v-model="filter.value"
                        size="mini"
                        :disabled="true"
                      ></el-input>

                      <el-select
                        v-model="filter.value_field"
                        v-if="filter.data_source == 'self_field'"
                        collapse-tags
                        size="mini"
                      >
                        <el-option
                          v-for="(enityData, index) of getSelfTemplateFields(
                            filter
                          )"
                          :key="index"
                          :label="enityData.label"
                          :value="enityData.key"
                        ></el-option>
                      </el-select>
                      <el-select
                        v-model="filter.value"
                        v-if="filter.data_source == 'field_option'"
                        collapse-tags
                        size="mini"
                        :multiple="
                          filter.operator == 'in' || filter.operator == 'nin'
                            ? true
                            : false
                        "
                      >
                        <template v-if="fieldType(filter, 'LIST')">
                          <el-option
                            v-for="(enityData, index) of getCurrentFieldOptions(
                              filter
                            )"
                            :key="index + '_' + enityData.value"
                            :label="enityData.name"
                            :value="enityData.value"
                            multiple
                            collapse-tags
                          ></el-option>
                        </template>
                        <template v-else-if="fieldType(filter, 'WEEKDAYS')">
                          <el-option
                            v-for="(option, index) of weekdays_options"
                            :value="option.key"
                            :label="option.name"
                            :key="index"
                          ></el-option>
                        </template>
                        <template v-else>
                          <el-option
                            v-for="(enityData, index) of getCurrentFieldOptions(
                              filter
                            )"
                            :key="index"
                            :label="enityData"
                            :value="enityData"
                            multiple
                            collapse-tags
                          ></el-option>
                        </template>
                      </el-select>
                    </div>
                </td>

                <td
                  v-if="
                    (((filter.data_source == 'GLOBAL_VARIABLE' &&
                      filter.global_variable_id) ||
                      filter.data_source == 'self_field') &&
                      checkIsSelect(filter)) ||
                    (filter.data_type == 'DATE' &&
                      filter.operator === 'real_time')
                  "
                >
                  <el-checkbox
                    v-if="
                      filter.operator === 'real_time' &&
                      everyYearFilters.indexOf(filter.selected_date_picker) ===
                        -1
                    "
                    v-model="filter.every_year"
                    >Show every year ?</el-checkbox
                  >
                  <el-select
                    size="mini"
                    v-model="filter.value"
                    v-if="filter.operator !== 'real_time'"
                  >
                    <el-option
                      v-for="(op, index) in getCurrentGlobalVaribleOptions(
                        filter
                      )"
                      :key="index"
                      :multiple="
                        filter.operator == 'in' || filter.operator == 'nin'
                          ? true
                          : false
                      "
                      :value="op"
                      :label="op"
                    ></el-option>
                  </el-select>
                </td>

                <td v-if="filter.data_table_key">
                  <el-tooltip
                    effect="dark"
                    content="Allow rows must satisfy this rule"
                    placement="top-start"
                  >
                    <el-checkbox v-model="filter.all_rows"></el-checkbox>
                  </el-tooltip>
                </td>
                <td>
                  <el-button
                    type="text"
                    size="mini"
                    @click="removeFilterFromList(index)"
                    >Remove</el-button
                  >
                </td>
              </tr>
            </td>
          </tr>
        </table>
      </el-scrollbar>
      <span class="warning"
        >Note: The quick filters of the first
        <em>Relation-with #Entity</em> Filter will be displayed alongside the
        current filter's quick filters.</span
      >
      <el-divider></el-divider>
      <div
        v-if="entityFiltersData.isSaveFilter || entityFiltersData._id"
        class="mt-1 d-flex"
      >
        <div v-if="entityFiltersData.isSaveFilter || entityFiltersData._id">
          <div style="margin-bottom: 10px">
            Filter name
            <span class="text-danger">*</span>
          </div>
          <el-input
            v-model="entityFiltersData.filter_name"
            placeholder="Enter filter name"
            size="mini"
          ></el-input>
        </div>
        <div>
          <span class="select-fields">
            <div style="margin-bottom: 10px">
              Select fields to use as quick filters
            </div>
            <el-select
              v-model="entityFiltersData.quick_filters"
              multiple
              placeholder="Select fields"
              :multiple-limit="10"
              size="mini"
              collapse-tags
              clearable
            >
              <el-option
                v-for="(field, index) of getQuickFilterFilters"
                :key="index + '_' + field.key"
                :value="field.key"
                :label="`${field.template_name} - ${field.label}`"
                :disabled="checkFilterExistedOrNot(field)"
                >{{ `${field.template_name} - ${field.label}` }}</el-option
              >
            </el-select>
          </span>
        </div>
        <div class="Filters-GroupBy">
          <div style="margin-bottom: 10px">Group by</div>
          <el-select
            v-model="entityFiltersData.tableFieldGroupBy"
            placeholder="Group by"
            size="mini"
            @change="addDefaultColumn"
            clearable
          >
            <el-option
              v-for="(field, index) of getQuickFilterFilters.filter(
                (e) =>
                  e.inputType != 'DATE' &&
                  entityFiltersData.tableFieldSubGroupBy != e.key
              )"
              :key="index + '_' + field.key"
              :value="field.key"
              :label="`${field.template_name} - ${field.label}`"
              >{{ `${field.template_name} - ${field.label}` }}</el-option
            >
          </el-select>
        </div>
        <div>
          <div style="margin-bottom: 10px">Sub group by</div>
          <el-select
            v-model="entityFiltersData.tableFieldSubGroupBy"
            placeholder="Sub group by"
            size="mini"
            @change="getSelectedFieldOptions"
            clearable
          >
            <el-option
              v-for="(field, index) of getQuickFilterFilters.filter(
                (e) =>
                  e.inputType != 'DATE' &&
                  entityFiltersData.tableFieldGroupBy != e.key
              )"
              :key="index + '_' + field.key"
              :value="field.key"
              :label="`${field.template_name} - ${field.label}`"
              >{{ `${field.template_name} - ${field.label}` }}</el-option
            >
          </el-select>
        </div>
        <div v-if="entityFiltersData.tableFieldSubGroupBy">
          <span class="opt">
            <p class="m-01">Options</p>
            <div class="select-wrapper">
              <el-select
                placeholder="Select options to group"
                multiple
                v-model="entityFiltersData.tableFieldGroupBySelectedOptions"
                clearable
                collapse-tags
                size="mini"
              >
                <template
                  v-if="
                    checkFieldType(
                      entityFiltersData.tableFieldSubGroupBy,
                      'ENTITY'
                    )
                  "
                >
                  <el-option
                    v-for="(data, index) of groupByFieldEntityValues"
                    :key="index + 'entity' + data._id"
                    :value="data._id"
                    :label="getLabelForEntityData(data, index)"
                  >
                    <span>{{ getLabelForEntityData(data, index) }}</span>
                  </el-option>
                </template>
                <template v-else>
                  <el-option
                    v-for="(option, index) of pieChartFieldGroupByOptions"
                    :value="option"
                    :key="index"
                    :label="option"
                  >
                    <span>{{ option }}</span>
                  </el-option>
                </template>
              </el-select>
            </div>
          </span>
        </div>
      </div>
      <div
        v-if="
          entityFiltersData.permissions &&
          (entityFiltersData.isSaveFilter || entityFiltersData._id)
        "
        class="mt-2"
      >
        <div class="d-flex" style="gap: 10px">
          <div class="flex-column">
            <span>Templates access</span>
            <el-select
              v-model="entityFiltersData.permissions.templates"
              multiple
              collapse-tags
              size="mini"
              @change="checkStandardTemplate"
            >
              <el-option
                v-for="(op, i) in getCurrentTemplates"
                :key="i + op._id"
                :value="op._id"
                :label="op.name"
                >{{ op.name }}</el-option
              >
            </el-select>
          </div>
          <div class="flex-column">
            <span>Display Type</span>
            <el-select
              v-model="entityFiltersData.displayType"
              placeholder="Select a view"
            >
              <el-option
                v-for="view in display_views"
                :key="view.key"
                :label="view.label"
                :value="view.key"
                >{{ view.label }}</el-option
              >
            </el-select>
          </div>
          <div class="Filters-Container">
            <!-- <div
              v-for="(filter, index) in filteredFilters"
              :key="index + '-' + filter.field"
              class="Filter-Row"
            > -->
              <!-- <div class="Filter-Label">
                {{ filter.label }}
              </div> -->
              <div class="Filter-Items">
                <div class="Filter-Item">
                  <div style="margin-bottom: 5px">Sort Field</div>
                  <el-select
                    v-model="entityFiltersData.sortBy"
                    placeholder="Select Entity Field"
                    size="mini"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="(field, fieldIndex) in selectedEntityFields.filter(
                        (e) => contentFields.indexOf(e.inputType) === -1
                      )"
                      :key="fieldIndex"
                       :value="matchingValue(field)"
                      :label="`${field.template_name} - ${field.label}`"
                    >
                      {{ `${field.template_name} - ${field.label}` }}
                    </el-option>
                  </el-select>
                </div>
                <div class="Filter-Item">
                  <div style="margin-bottom: 5px">Sort Order</div>
                  <el-select
                     v-model="entityFiltersData.sortOrder"
                    placeholder="Select Order"
                    size="mini"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="(option, optIndex) in sortOrderOptions"
                      :key="optIndex"
                      :value="option.value"
                      :label="option.label"
                    >
                      {{ option.label }}
                    </el-option>
                  </el-select>
                </div>
              </div>
          </div>

          <div
            class="flex-column"
            v-if="
              entityFiltersData.displayType &&
              entityFiltersData.displayType == 'HTML_CONTENT'
            "
          >
            <span>Select Email Template</span>
            <el-select
              v-model="entityFiltersData.email_template_id"
              placeholder="Select a view"
            >
              <el-option
                v-for="item in emailTemplates"
                :key="item._id"
                :label="item.name"
                :value="item._id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </div>
          <div
            v-if="entityFiltersData.displayType == 'TABLE'"
            class="flex-column"
          >
            <span> Data limit</span>
            <el-select
              v-model="entityFiltersData.data_limit"
              placeholder="Select a limit"
            >
              <el-option
                v-for="limit in [5, 10, 20, 50, 100]"
                :key="limit + '_limit'"
                :label="limit"
                :value="limit"
                >{{ limit }}</el-option
              >
            </el-select>
          </div>
        </div>
        <br />
        <div class="d-flex mt-1">
          <p class="mr-2 textDisplay">Permissions</p>
          <el-checkbox-group
            v-model="entityFiltersData.permissions.access_set"
            @change="updatePermissions"
          >
            <el-checkbox
              v-for="(access, i) in [
                'VIEW',
                'ADD',
                'UPDATE',
                'DUPLICATE',
                'DELETE',
                'IMPORT',
                'EXPORT',
                'LAYOUT',
                'SETTINGS',
                'ROW DATA EDIT',
                'FILTER',
              ]"
              :key="i + access"
              :label="access"
              :disabled="checkPermissions(access)"
              >{{ access }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </div>
      <el-checkbox
        class="mt-1"
        v-model="entityFiltersData.isSaveFilter"
        v-if="
          entityFiltersData &&
          !entityFiltersData._id &&
          ((entityFiltersData.filters && entityFiltersData.filters.length) ||
            (entityFiltersData.quick_filters &&
              entityFiltersData.quick_filters.length))
        "
        >Save this filter for future use or custom menu
        configuration?</el-checkbox
      >
      <el-checkbox
        class="mt-1"
        v-model="entityFiltersData.updateFilter"
        v-if="entityFiltersData._id"
        >Update this filter?</el-checkbox
      >
    </el-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";

export default {
  components: {
    CustomDatePicker: () => import("@/components/widgets/customDatePicker.vue"),
  },
  mixins: [CustomDashboardConfig],
  props: [
    "currentEntity",
    "entityFiltersData",
    "selectedEntityFields",
    "emailTemplates",
    "Customization"
  ],
  data() {
    return {
      sortOrderOptions: [
        { label: "Ascending", value: "asc" },
        { label: "Descending", value: "desc" },
      ],
      sortBy: "",
      sortOrder: "",

      weekdays_options: [
        { name: "Monday", value: 1, key: "MON" },
        { name: "Tuesday", value: 2, key: "TUE" },
        { name: "Wednesday", value: 3, key: "WED" },
        { name: "Thursday", value: 4, key: "THU" },
        { name: "Friday", value: 5, key: "FRI" },
        { name: "Saturday", value: 6, key: "SAT" },
        { name: "Sunday", value: 0, key: "SUN" },
      ],
      users: [],
      starRating_options: [
        { name: "1", value: 1 },
        { name: "1.5", value: 1.5 },
        { name: "2", value: 2 },
        { name: "2.5", value: 2.5 },
        { name: "3", value: 3 },
        { name: "3.5", value: 3.5 },
        { name: "4", value: 4 },
        { name: "4.5", value: 4.5 },
        { name: "5", value: 5 },
      ],
      everyYearFilters: ["this_year", "last_year"],
      allowedLoginFilterOp: [
        "=text-includes",
        "=case-insensitive",
        "=case-sensitive",
      ],
      default_relational_filter_query : "includes",

      newFilterData: {
        field: "",
        operator: "",
        value: "",
        value_type: "",
        value_source: "",
        data_type: "",
        query_type: "AND",
        value_field: "",
        validations: {
          data_type: "",
        },
        relation_entity_id: "",
        relational_filter_query : "includes"
      },
      query_type: "AND",
      operatorsMap: {
        NUMBER: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Range",
            value: "between",
          },
        ],
        PHONE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        CONCATENATE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        STRING: [
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          {
            title: "Text Includes",
            value: "=text-includes",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Starts With",
            value: "=starts-with",
          },
          {
            title: " Not Starts With",
            value: "=not-starts-with",
          },
          {
            title: "Ends With",
            value: "=ends-with",
          },
          {
            title: "Not Ends With",
            value: "!ends-with",
          },
        ],
        PAYMENT: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Equals (Case-sensitive)",
            value: "=case-sensitive",
          },
          {
            title: " Not Equals (Case-sensitive)",
            value: "!=case-sensitive",
          },
          {
            title: "Equals (Case-insensitive)",
            value: "=case-insensitive",
          },
          {
            title: " Not Equals (Case-insensitive)",
            value: "!=case-insensitive",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        OBJECT_ID: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],
        DATE: [
          {
            title: "Real time picker",
            value: "real_time",
          },
          {
            title: "Match with weekdays",
            value: "weekdays",
          },
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Between",
            value: "between",
          },
          {
            title: "NotBetween",
            value: "notbetween",
          },
          {
            title: "Before",
            value: "before",
          },
          {
            title: "After",
            value: "after",
          },
        ],
        TIME: [
          {
            title: "Equals (=)",
            value: "=text-includes",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Between",
            value: "between",
          },
          {
            title: "NotBetween",
            value: "notbetween",
          },
        ],
        RELATION: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Filter",
            value: "filter",
          },
        ],
        FILE: [
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
        ],
        ARRAY: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        DATE_TIME: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Date Includes",
            value: "dateinclude",
          },
          {
            title: "Date Not Includes",
            value: "notdateinclude",
          },
        ],
        WEEKDAYS: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ],
        TIME_RANGE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Time includes",
            value: "timeinclude",
          },
        ],
        YES_OR_NO: [
          {
            title: "Equals",
            value: "=case-sensitive",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        CURRENCY: [
          {
            title: "Equals (=)",
            value: "=",
          },
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Greater Than or Equal(>=)",
            value: ">=",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Less Than or Equal(<=)",
            value: "<=",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Range",
            value: "between",
          },
        ],
        BOOLEAN: [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
        ],
        DATE_RANGE: [
          {
            title: "Exist",
            value: "exists",
          },
          {
            title: "Not Exist",
            value: "notexists",
          },
          {
            title: "Date includes",
            value: "dateinclude",
          },
        ],
      },

      operatorValueTypeMap: [
        {
          value_type: "VALUE",
          operators: [
            "=",
            "<=",
            "<",
            ">",
            ">=",
            "=case-insensitive",
            "=case-sensitive",
            "=text-includes",
            "!=",
            "dateinclude",
            "timeinclude",
            "in",
            "nin",
            "=starts-with",
            "=not-starts-with",
            "=ends-with",
            "!ends-with",
            "after",
            "before",
          ],
          data_types: [
            "NUMBER",
            "STRING",
            "DATE",
            "TIME",
            "DATE_TIME",
            "TIME_RANGE",
            "YES_OR_NO",
            "CURRENCY",
            "LIST",
            "WEEKDAYS",
            "BOOLEAN",
            "created_at",
            "updated_at",
            "created_by",
            "updated_by",
          ],
        },
        {
          value_type: "GLOBAL_VARIABLE",
          operators: [
            "=",
            "<=",
            ">=",
            "<",
            ">",
            "=case-insensitive",
            "=text-includes",
            "=case-sensitive",
            "!=",
          ],
          data_types: ["NUMBER", "STRING"],
        },
        {
          value_type: "NEW_LIST",
          operators: ["in", "nin"],
          data_types: ["NUMBER"],
        },

        {
          value_type: "ENTITY_DATA_LIST",
          operators: ["in", "nin"],
          data_types: ["OBJECT_ID", "RELATION"],
        },
        {
          value_type: "REAL_TIME_PICKER",
          operators: ["dateinclude"],
          data_types: ["DATE_TIME"],
        },
        {
          value_type: "CURRENT_DATE",
          operators: ["=", "<=", "<", ">", ">="],
          data_types: ["DATE_TIME", "DATE"],
        },
      ],
      globalVariablesList: [],
      globalVariablesListForInputType: {},
      filters: [],
      entityDataInfo: [],
      loginUserFields: [
        {
          value: "email",
          label: "Email",
        },
        {
          value: "first_name",
          label: "First Name",
        },
        {
          value: "last_name",
          label: "Last Name",
        },
      ],
      groupByFieldEntityValues: [],
      pieChartFieldGroupByOptions: [],
      getTemplateFields: [],
      contentFields: [
        "HEADING",
        "PARAGRAPH",
        "HTML_CONTENT",
        "SINGLE_LINE_CONTENT",
        "VIDEO",
        "ACTION_BUTTON",
        "HORIZONTAL_LINE",
        "DIVISION",
      ],
      allEntitiesFilters: [],
      display_views: [
        {
          label: "Table",
          key: "TABLE",
        },
        {
          label: "Cards",
          key: "CARDS",
        },
        {
          label: "Content",
          key: "HTML_CONTENT",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("companyEntities", [
      "getAllCompanyEntitiesData",
      "getEntityFields",
    ]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    ...mapGetters("entitiesData", ["getEntityPrimaryFieldsDataWithHash"]),
    ...mapGetters("filters", ["getFiltersOfAllEntities"]),
    ...mapGetters("entities", [
      "getEntityDataByEntityId",
      "getEntityById",
      "getEntityRecordsForTable",
    ]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getCompanyUsers",
      "getAuthenticatedUser",
    ]),
    checkPermissions() {
      return (access) => {
        //this.entityFiltersData.permissions.access_set
        if (access == "VIEW" || access == "DELETE") {
          return false;
        }
        if (
          (access == "ADD" &&
            this.entityFiltersData.permissions.access_set.indexOf("UPDATE") ==
              -1) ||
          this.entityFiltersData.permissions.access_set.indexOf("VIEW") == -1
        ) {
          return true;
        }
        if (
          access == "UPDATE" &&
          this.entityFiltersData.permissions.access_set.indexOf("VIEW") == -1
        ) {
          return true;
        }
        if (
          access === "ROW DATA EDIT" &&
          !this.entityFiltersData.permissions.access_set.includes("UPDATE")
        ) {
          return true;
        }
        return false;
      };
    },
    getQuickFilterFilters() {
      let existedOnes = this.entityFiltersData.filters.filter((e) => e.field);
      return this.selectedEntityFields.filter(
        (e) =>
          (e.inputType == "SELECT" ||
            e.inputType == "ENTITY" ||
            e.inputType == "MULTI_SELECT" ||
            e.inputType == "DATE" ||
            e.input_type == "DATE_TIME") &&
          existedOnes.indexOf(e.key) === -1
      );
    },
    entilistAsOptions() {
      return (filter) => {
        let field = this.selectedEntityFields.find(
          (e) => e.key == filter.field
        );
        if (
          field &&
          field.entity_id &&
          this.entityDataInfo &&
          this.entityDataInfo.length
        ) {
          let selectedEntity = this.entityDataInfo.find(
            (e) => e.entity_id === field.entity_id
          );
          if (selectedEntity && selectedEntity.data) {
            return selectedEntity.data;
          }
        } else {
          return [];
        }
      };
    },
    getCurrentTemplates() {
      if (this.currentEntity?.templates) {
        return this.currentEntity?.templates.map((e) => {
          return {
            ...e.templateInfo,
          };
        });
      }
      return [];
    },
  },
  created() {
    this.setDefaultDisplayType();
  },
  async mounted() {
    this.getCompanyUsersList();
    await this.getFiltersOfRelationalEntities();
    await this.fetchGlobalVariables();
    if (!this.entityFiltersData.sortBy) {
      this.$set(this.entityFiltersData, "sortBy", "");
    }
    if (!this.entityFiltersData.sortOrder) {
      this.$set(this.entityFiltersData, "sortOrder", "");
    }
    if (!this.entityFiltersData.quick_filters) {
      this.$set(this.entityFiltersData, "quick_filters", []);
    }
    if (!this.entityFiltersData.permissions) {
      let currentEntities = [
        ...this.currentEntity.templates.flatMap((e) => e.template_id),
      ];
      this.$set(this.entityFiltersData, "permissions", {
        templates: [...currentEntities],
        access_set: ["VIEW", "ADD", "UPDATE", "DELETE"],
      });
    }
    let defaultValueSet = false;
    if (
      this.entityFiltersData.filters &&
      this.entityFiltersData.filters.length
    ) {
      this.entityFiltersData.filters.forEach((element) => {
        if (
          element &&
          (element.data_type == "RELATION" ||
            element.data_type == "OBJECT_ID") &&
          element.relation_entity_id
        ) {
          this.fetchEntityPrimaryFieldsData(element.relation_entity_id);
        }
        if(!defaultValueSet && element.relational_filter_query){
         this.default_relational_filter_query = element.relational_filter_query;
          defaultValueSet = true;
        }
      });
    }
    this.getTemplateFields = [];
    this.getTemplateFields = this.getAllFieldsFormEntity(this.currentEntity);
  },
  methods: {
    matchingValue(field) {
    if (field.inputType === "ENTITY") {
      return `${field.key}/name`;
    }
    return field.key;
  },

    getButtonLabel(buttonName, defaultLabel) {
    const button = this.Customization?.buttons?.find(
      (bt) => bt.buttonName === buttonName
    );
    return button ? button.value : defaultLabel;
    },
    setDefaultDisplayType() {
      const viewType = this.$route.query.viewType;
      if (viewType && viewType.toUpperCase() === "CARDS") {
        this.entityFiltersData.displayType = "CARDS";
      } else {
        this.entityFiltersData.displayType = "TABLE";
      }
    },
    async getCompanyUsersList() {
      try {
        await this.$store.dispatch(
          "auth/getCompanyUsersList",
          this.getAuthenticatedUser.current_workspace.company_id
        );
        this.users = this.$store.getters["auth/getCompanyUsers"];
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err,
        });
      }
    },
    getSelfTemplateFieldsByType(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (
        (field && field.inputType === "NUMBER") ||
        field.inputType === "CURRENCY" ||
        field.inputType === "AGGREGATE_FUNCTION" ||
        (field.inputType === "FORMULA" && field.result_type === "NUMBER")
      ) {
        return (this.selectedEntityFields || []).filter(
          (e) =>
            (e.data_type === "NUMBER" ||
              (e.input_type === "FORMULA" && e.result_type !== "DATE") ||
              e.input_type === "AGGREGATE_FUNCTION" ||
              (e.input_type === "ENTITY_VARIABLE" &&
                e.inputType === "NUMBER")) &&
            e.key !== field.key
        );
      }
      if (
        (field && field.inputType === "DATE") ||
        field.inputType === "FORMULA"
      ) {
        return (this.selectedEntityFields || []).filter(
          (e) =>
            (e.data_type === "DATE" ||
              (e.input_type === "FORMULA" && e.result_type === "DATE")) &&
            e.key !== field.key
        );
      } else if (field && field.input_type) {
        return (this.selectedEntityFields || []).filter(
          (e) =>
            e?.key &&
            ((field.inputType != "ENTITY" && e.inputType == field.inputType) ||
              (field.inputType == "ENTITY" &&
                field.entity_id == e.entity_id &&
                e.inputType == "ENTITY") ||
              (["MULTI_SELECT", "SELECT"].indexOf(field.inputType) != -1 &&
                ["MULTI_SELECT", "SELECT"].indexOf(e.inputType) != -1) ||
              (["TIME", "TIME_RANGE"].indexOf(field.inputType) != -1 &&
                ["TIME", "TIME_RANGE"].indexOf(e.inputType) != -1)) &&
            e.key != field.key
          // e.relationship_key != this.field.key
        );
      }

      return [];
    },
    addGlobalVaraibleData(value, filter) {
      let selectedGlobalVariable = this.selectedGlobalVariablesList(
        filter
      ).find((e) => e._id == value);
      if (selectedGlobalVariable?.value) {
        filter.value = selectedGlobalVariable.value;
      }
    },
    addDefaultColumn() {
      this.$set(this.entityFiltersData, "tableFieldGroupByFields", [
        {
          key: "count",
          name: "Count",
          type: "count",
          sub_group: false,
        },
      ]);
    },
    fieldType(filter, type) {
      if (filter.field) {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        return field?.input_type == type;
      }
      return [];
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.allTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    allTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        return e;
      });
      return fields;
    },
    getLabelForEntityData(item, i) {
      let selectedEntity = this.groupByFieldEntity
        ? this.groupByFieldEntity
        : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    checkStandardTemplate(data) {
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        let standard = this.getCurrentTemplates.find(
          (e) => e.type == "STANDARD"
        );
        if (standard?._id && data.indexOf(standard?._id) == -1) {
          this.$message({
            message:
              "Warning, Contact details need to be selected for individual entity.",
            type: "warning",
          });
          this.entityFiltersData.permissions.templates = [
            ...this.entityFiltersData.permissions.templates,
            ...[standard?._id],
          ];
        }
      }
    },
    updatePermissions(data) {
      if (data.indexOf("VIEW") == -1) {
        let update = data.indexOf("UPDATE");
        let add = data.indexOf("ADD");
        if (update > -1) {
          this.entityFiltersData.permissions.access_set.splice(update, 1);
        }
        if (add > -1) {
          this.entityFiltersData.permissions.access_set.splice(add, 1);
        }
      }
      if (data.indexOf("UPDATE") == -1) {
        let add = data.indexOf("ADD");
        let rowDataEdit = data.indexOf("ROW DATA EDIT");
        if (add > -1) {
          this.entityFiltersData.permissions.access_set.splice(add, 1);
        }
        if (rowDataEdit > -1) {
          this.entityFiltersData.permissions.access_set.splice(rowDataEdit, 1);
        }
      }
    },
    setDatePickerDate(value, filter) {
      let data = this.getDatePickerHelper(value);
      filter.value = data.value;
    },
    checkCurrentFieldValue(field, filter) {
      filter.value = field;
    },
    getLabel(item, i) {
      let selectedEntity = (this.entityDataInfo || []).find(
        (e) => e.entity_id == item.entity_id
      );
      if (selectedEntity && selectedEntity.entity) {
        selectedEntity = selectedEntity.entity;
      }
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          name =
            item.entityData &&
            primaryField.template_id &&
            item.entityData[primaryField.template_id]
              ? item.entityData[primaryField.template_id][key + "/name"]
                ? item.entityData[primaryField.template_id][key + "/name"]
                : item.entityData[primaryField.template_id][key]
                ? item.entityData[primaryField.template_id][key]
                : ""
              : "";
          if (!name) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          }
        }
      }
      return name;
    },
    updateQuery() {
      if (
        this.entityFiltersData &&
        this.entityFiltersData.filters &&
        this.entityFiltersData.filters[0]
      ) {
        this.entityFiltersData.filters[0] = {
          ...this.entityFiltersData.filters[0],
          ...{ query_type: this.query_type },
        };
      }
    },
    deleteFilter() {
      this.$emit("delete", {});
    },
    addFilter() {
      this.entityFiltersData.filters.push({
        ...this.newFilterData,
        ...{ query_type: this.query_type,relational_filter_query : this.default_relational_filter_query },
      });
      let scrollbarEl = this.$refs.filterScroll.wrap;
      scrollbarEl.scrollTop = scrollbarEl.scrollHeight + 10;
    },
    checkFilterExistedOrNot(field) {
      let checkQuery = this.entityFiltersData.filters[0].query_type;
      return checkQuery
        ? false
        : this.entityFiltersData.filters.find((x) => x.field == field.key) ||
          (this.entityFiltersData.quick_filters &&
            this.entityFiltersData.quick_filters.indexOf(field.key) !== -1)
        ? true
        : false;
    },
    async checkCurrentFieldData(fieldKey, filter) {
      if (fieldKey == "status") {
        filter.data_type = "STATUS";
        filter.data_source = "VALUE";
        return;
      }
      if (fieldKey == "isCreatedFromFormbuilder") {
        filter.data_type = "isCreatedFromFormbuilder";
        filter.data_source = "VALUE";
        return;
      }
      if (fieldKey == "created_at") {
        filter.data_type = "DATE";
        filter.input_type = "created_at";

        return;
      }
      if (fieldKey == "updated_at") {
        filter.data_type = "DATE";
        filter.input_type = "updated_at";

        return;
      }
      if (fieldKey == "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";

        return;
      }
      if (fieldKey == "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";

        return;
      }
      let field = this.selectedEntityFields.find(
        (field) => field.key == fieldKey
      );
      if (field.inputType == "DATE_TIME" || field.inputType == "DATE") {
        filter.is_date_time = true;
      } else {
        filter.is_date_time = false;
      }
      if (field?.data_table_key) {
        filter.data_table_key = field.data_table_key;
      }
      if (field.input_type == "WEEKDAYS") {
        filter.allow_multiple = field.allow_multiple;
      } else if (field.input_type == "FORMULA") {
        filter.data_type = field.result_type;
        filter.is_formula = true;
      } else if (
        field.input_type == "ENTITY_VARIABLE" &&
        field.inputType == "FORMULA"
      ) {
        filter.data_type = this.getTypeFromInputType(field.result_type);
        field.data_type = this.getTypeFromInputType(field.result_type);
      } else {
        filter.data_type = this.getTypeFromInputType(field.inputType);
        field.data_type = this.getTypeFromInputType(field.inputType);
      }
      if (field.input_type == "RELATION" || field.data_type == "OBJECT_ID") {
        filter.relation_entity_id = field.entity_id;
      }
      this.$set(filter, "data_source", "");
      if (field.inputType == "SELECT" || field.inputType == "MULTI_SELECT") {
        this.$set(filter, "value", []);
      } else {
        this.$set(filter, "value", "");
      }
      filter.input_type = field.input_type;
      await this.getEntilistAsOptions(filter);
    },
    setRelationalFilterQuery(value){
      this.entityFiltersData.filters?.map(e => {
        if(e.data_type == 'RELATION' && e.operator == 'filter') {
          e.relational_filter_query = value;
        }
      })
      this.default_relational_filter_query = value;
    },
    checkIsFirstRelationalField(index) {
      let firstRelationShipFieldIndex = this.entityFiltersData.filters?.findIndex(e => e.data_type == 'RELATION' && e.operator == 'filter');
      return index == firstRelationShipFieldIndex
    },
    getTypeFromInputType(inputType) {
      const obj = {
        SINGLE_LINE_TEXT: "STRING",
        MULTI_LINE_TEXT: "STRING",
        DATE: "DATE",
        DATE_TIME: "DATE",
        TIME: "TIME",
        NUMBER: "NUMBER",
        FILE: "FILE",
        SELECT: "ARRAY",
        YES_OR_NO: "YES_OR_NO",
        MULTI_SELECT: "ARRAY",
        CHECKBOX_GROUP: "ARRAY",
        RADIO_BUTTON_GROUP: "ARRAY",
        CHECKBOX: "BOOLEAN",
        RADIO: "STRING",
        HEADING: "STRING",
        SIGNATURE: "FILE",
        GLOBAL_VARIABLE: "OBJECT_ID",
        TEMPLATE_FIELD: "OBJECT_ID",
        ENTITY: "OBJECT_ID",
        IMAGE: "STRING",
        PASSWORD: "STRING",
        MASKED: "STRING",
        EMAIL: "EMAIL",
        PHONE: "PHONE",
        FAX: "NUMBER",
        ZIP_CODE: "ZIP_CODE",
        CONCATENATE: "CONCATENATE",
        CURRENCY: "NUMBER",
        PHONE_COUNTRY_CODE: "PHONE",
        QUESTION: "STRING",
        DATE_TIME_RANGE: "DATE_TIME",
        DATE_RANGE: "DATE_RANGE",
        WEEKDAYS: "WEEKDAYS",
        TIME_RANGE: "TIME_RANGE",
        RELATION: "RELATION",
        LIST: "ARRAY",
        Hyper_link: "STRING",
        STAR_RATING: "ARRAY",
        PAY_BUTTON: "PAYMENT",
        AGGREGATE_FUNCTION: "NUMBER",
        AUTO_INCREMENT_NUMBER: "STRING",
      };
      return obj[inputType];
    },
    removeFilterFromList(index) {
      if (index > -1) {
        this.entityFiltersData.filters.splice(index, 1);
      }
    },
    getCurrentGlobalVaribleOptions(filter) {
      if (filter.data_source == "self_field") {
        let selectedField = this.fieldsData.find(
          (e) => e.key == filter.value_field
        );
        if (selectedField && selectedField.options) {
          return selectedField.options;
        }
      } else {
        let globalVariable = this.globalVariablesList.find(
          (e) => e._id == filter.global_variable_id
        );
        if (globalVariable && globalVariable.options) {
          return globalVariable.options;
        }
      }
      return [];
    },
    getCurrentFieldOptions(filter) {
      if (filter.selectField === "created_at") {
        filter.data_type = "DATE";
        filter.input_type = "created_at";
        return;
      }
      if (filter.selectField === "updated_at") {
        filter.data_type = "DATE";
        filter.input_type = "updated_at";
        return;
      }
      if (filter.selectField === "created_by") {
        filter.data_type = "created_by";
        filter.input_type = "created_by";
        return;
      }
      if (filter.selectField === "updated_by") {
        filter.data_type = "updated_by";
        filter.input_type = "updated_by";
        return;
      }
      if (filter.field) {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        if (field?.input_type == "LIST" && field?.list_data) {
          return field.list_data;
        }
        if (field && field.input_type == "PAY_BUTTON" && field.pay_status) {
          return field.pay_status;
        } else if (field?.input_type == "WEEKDAYS") {
          let weekdays_names = this.weekdays_options.map(
            (weekday) => weekday.name
          );
          return weekdays_names;
        } else if (
          field?.input_type == "STAR_RATING" ||
          (field?.input_type == "ENTITY_VARIABLE" &&
            field?.inputType == "STAR_RATING")
        ) {
          let starRating_values = this.starRating_options.map(
            (starRating) => starRating.value
          );
          return starRating_values;
        } else {
          return field?.options || [];
        }
      } else {
        return [];
      }
    },
    getSelfTemplateFields(filter) {
      if (filter.field) {
        let field = this.selectedEntityFields.find(
          (field) => field.key == filter.field
        );
        if (field && field.input_type) {
          return (
            (this.fieldsData || []).filter(
              (e) => e.input_type == field.input_type
            ) || []
          );
        }
      } else {
        return [];
      }
    },
    selectedGlobalVariablesList(filter) {
      let field = this.selectedEntityFields.find((e) => e.key == filter.field);
      return this.globalVariablesList.filter(
        (x) => x && field && x.input_type == field.input_type
      );
    },
    parseOptionsAsNumbers(data, filter) {
      if (filter.data_source == "NEW_LIST" && filter.data_type == "NUMBER") {
        filter.value = filter.value.map((e) => +e);
      }
    },
    getReadbleValues(primaryFieldsData) {
      return Object.values(primaryFieldsData).join(" ");
    },
    checkSelfTemplateFields(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (field && field.input_type) {
        return (this.fieldsData || []).filter(
          (e) => e.input_type == field.input_type
        ).length
          ? true
          : false;
      }
      return false;
    },
    checkIsSelect(filter) {
      let field = this.selectedEntityFields.find(
        (field) => field.key == filter.field
      );
      if (
        field &&
        (field.inputType == "SELECT" ||
          field.inputType == "MULTI_SELECT" ||
          field.inputType == "CHECKBOX_GROUP" ||
          field.input_type == "RADIO_BUTTON_GROUP" ||
          field.inputType == "LIST" ||
          field.input_type == "WEEKDAYS" ||
          field.inputType == "STAR_RATING" ||
          field.input_type == "PAY_BUTTON")
      ) {
        return true;
      }
      return false;
    },
    allowedDataSources(filter) {
      let operatorValues = this.operatorValueTypeMap.filter((valueType) => {
        return (
          valueType.operators.indexOf(filter.operator) > -1 &&
          valueType.data_types.indexOf(filter.data_type) > -1
        );
      });
      return operatorValues.map((e) => e.value_type);
    },
    async getGlobalVariables(value, filter) {
      if (value) {
        // we have to fetch all global variables
        let fieldKey = filter.field;

        let field = this.selectedEntityFields.find(
          (field) => field.key == fieldKey
        );

        if (field && field.input_type) {
          await this.fetchGlobalVariables();
        }
      }
    },

    async fetchGlobalVariables() {
      try {
        //const query = { get_all: true };

        //await this.$store.dispatch(
        //  "globalVariables/fetchGlobalVariables",
        //  query
        //);

        this.globalVariablesList = [...this.getAllGlobalVariables.data];
      } catch (err) {
        console.log(err);
      }
    },

    async checkSourceDataChange(source, filter) {
      if (
        filter.data_type == "created_by" ||
        filter.data_type == "updated_by"
      ) {
        return;
      }
      if (source == "GLOBAL_VARIABLE") {
        await this.getGlobalVariables(source, filter);
        // we have to fetch global variables
      }
      // else if (source == "ENTITY_DATA_LIST") {
      //   await this.getEntilistAsOptions(filter);
      // }
    },
    isFixed(filter) {
      let fieldKey = filter.field;
      let field = this.selectedEntityFields.find(
        (field) => field.key == fieldKey
      );
      return field.is_fixed;
    },
    async getEntilistAsOptions(filter) {
      let fieldKey = filter.field;
      let field = this.selectedEntityFields.find(
        (field) => field.key == fieldKey
      );

      // if (field.input_type == "SELF_ENTITY_DATA") {
      //   this.entilistAsOptions = await this.fetchEntityPrimaryFieldsData(
      //     field.entity_id
      //   );
      // } else
      if (field.input_type == "ENTITY" || field.input_type == "RELATION") {
        await this.fetchEntityPrimaryFieldsData(field.entity_id);
      }
    },
    async fetchEntityPrimaryFieldsData(entity_id) {
      let params = {
        entity_id: entity_id,
        template_fields_data: [],
        filters: [],
      };
      await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      if (this.getEntityRecordsForTable && this.getEntityRecordsForTable.data) {
        this.entityDataInfo.push({
          entity: this.getEntityRecordsForTable.selectedEntity,
          data: this.getEntityRecordsForTable.data,
          entity_id: entity_id,
        });
      }
    },
    getCurrentFieldOperators(filter) {
      if (
        filter.field == "status" ||
        filter.data_type == "STATUS" ||
        filter.field == "isCreatedFromFormbuilder"
      ) {
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (
        filter.data_type == "created_at" ||
        filter.data_type == "updated_at"
      ) {
        return [
          {
            title: "Greater Than (>)",
            value: ">",
          },
          {
            title: "Less Than (<)",
            value: "<",
          },
          {
            title: "Last Month",
            value: "lastmonth",
          },
          {
            title: "This Month",
            value: "thismonth",
          },
        ];
      }
      if (
        filter.data_type == "created_by" ||
        filter.data_type == "updated_by"
      ) {
        return [
          {
            title: "In",
            value: "in",
          },
          {
            title: "Not In",
            value: "nin",
          },
        ];
      }
      if (filter.field) {
        if (filter.data_type) {
          return this.operatorsMap[filter.data_type];
        } else {
          let field = this.selectedEntityFields.find(
            (field) => field.key == filter.field
          );
          if (
            field &&
            field.data_type &&
            field.result_type &&
            field.result_type.length
          ) {
            return this.operatorsMap[field.result_type];
          } else if (field && field.data_type) {
            return this.operatorsMap[field.data_type];
          } else {
            return [];
          }
        }
      } else {
        return [];
      }
    },
    checkTemplateCondition(filter) {
      if (
        filter.data_type != "created_by" &&
        filter.data_type != "updated_by" &&
        filter.operator != "filter"
      ) {
        return true;
      }
    },
    getSelectedRelationalEntityFilters(filter) {
      if (filter.operator == "filter" && this.allEntitiesFilters?.length) {
        return this.allEntitiesFilters.filter(
          (e) => e.entity_id == filter.relation_entity_id
        );
      }
    },
    async getFiltersOfRelationalEntities() {
      try {
        const entityIds = this.selectedEntityFields
          .filter((e) => e.data_type === "RELATION")
          .map((e) => e.entity_id);
        let params = {
          entityIds: entityIds,
        };
        if (entityIds.length) {
          await this.$store.dispatch(
            "filters/fetchAllFiltersByEntityIds",
            params
          );
        }
        if (this.getFiltersOfAllEntities?.length) {
          this.allEntitiesFilters = this.getFiltersOfAllEntities;
        }
      } catch (err) {
        console.log("getFiltersOfRelationalEntities", err);
      }
    },
  },
};
</script>
<style>
.textDisplay {
  white-space: nowrap;
}
</style>

<style lang="scss">
.Filters-Container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Filter-Row {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Filter-Label {
  font-weight: bold;
}

.Filter-Items {
  display: flex;
  gap: 1rem;
}

.Filters-GroupBy {
  margin-left: 20px;
}
.counter-box {
  display: flex;
  align-items: center;
}
.el-select .el-select__tags > span {
  display: flex;
  overflow-x: auto;
}
</style>
<style scoped>
.el-select-dropdown__item {
  font-size: 14px;
  padding: 8px 20px;
  position: relative;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: auto;
  line-height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  width: 200px;
}
.opt {
  position: relative;
  bottom: 5px;
}
.select-fields {
  position: relative;
  bottom: 0px;
}
.flex-column {
  display: flex;
  flex-direction: column !important;
}
</style>
